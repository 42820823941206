document.addEventListener('DOMContentLoaded', function(){
    navegacionFija();
    scrollNav();
    animationSv();
    animationNs();
    animationCt();
    animationtr();
    animationNota();
    underline();
    menutlf();
})

function menutlf(){
    const menu = document.querySelector(".menu-tlf");
    const navegartlf= document.querySelector(".navegacion");
    const navegartlflogo= document.querySelector(".logo");
    const navegartlfbarra= document.querySelector(".barra_contenido");
    const navegartlfseleccion= document.querySelector(".seleccion");
    const navegartlfidioma= document.querySelector(".idioma");
    const navegartlfidioma1= document.querySelector(".idioma1");
        menu.addEventListener('click', function(){
           navegartlf.classList.toggle('mostrar');
           navegartlflogo.classList.toggle('mostrar');
           navegartlfbarra.classList.toggle('mostrar');
           navegartlfseleccion.classList.toggle('mostrar');
           navegartlfidioma.classList.toggle('mostrar');
           navegartlfidioma1.classList.toggle('mostrar');
})
}

function underline(){
    const ns = document.querySelector('.ns');
    const sv = document.querySelector('.sv');
    const ct = document.querySelector('.ct');
    const servicios = document.querySelector('.servicios');
    const nosotros = document.querySelector('.nosotros');
    const contactanos = document.querySelector('.contactanos');
    window.addEventListener('scroll', function(){
        if (nosotros.getBoundingClientRect().top<100){
            ns.classList.add('under')
            sv.classList.remove('under')
            ct.classList.remove('under')
          //body.classList.add('body-scroll')
        }
        else{
            ns.classList.remove('under')
        }
    });

    window.addEventListener('scroll', function(){
        if (servicios.getBoundingClientRect().top<150){
            ns.classList.remove('under')
            ct.classList.remove('under')
            sv.classList.add('under')
            //body.classList.add('body-scroll')
        }

    });

    window.addEventListener('scroll', function(){
        if (contactanos.getBoundingClientRect().top<150){
            ns.classList.remove('under')
            sv.classList.remove('under')
            ct.classList.add('under')
            //body.classList.add('body-scroll')
        }
        
    });
}

function animationNs(){
    const animationNs = document.querySelector('.nosotros h2');
    const animationimg = document.querySelector('.nosotros_img_contenido');
    const nosotros = document.querySelector('.nosotros');

    window.addEventListener('scroll', function(){
        if (nosotros.getBoundingClientRect().top<800){
            animationNs.classList.add('animation')
            animationimg.classList.add('animation_ns');
            //body.classList.add('body-scroll')
        }
    });
}

function animationSv(){
    const animationSv = document.querySelector('.servicios_contenido h2');
    const servicios = document.querySelector('.servicios');

    window.addEventListener('scroll', function(){
        if (servicios.getBoundingClientRect().top<700){
            animationSv.classList.add('animation_sv')
            
            //body.classList.add('body-scroll')
        } 
    });
}

function animationCt(){
    const animationCt = document.querySelector('.contactanos .contactanos_contenido');
    const contactanos = document.querySelector('.contactanos');

    window.addEventListener('scroll', function(){
        if (contactanos.getBoundingClientRect().top<650){
            animationCt.classList.add('animationCt')
            //body.classList.add('body-scroll')
        }
    });
}

function animationtr(){
    const animationtr = document.querySelector('.trabajemos h3');
    const trabajemos = document.querySelector('.trabajemos');

    window.addEventListener('scroll', function(){
        if (trabajemos.getBoundingClientRect().top<650){
            animationtr.classList.add('animation_tr')
            //body.classList.add('body-scroll')
        }
    });
}

function animationNota(){
    const animationNota = document.querySelector('.nota h3');
    const nota = document.querySelector('.nota');

    window.addEventListener('scroll', function(){
        if (nota.getBoundingClientRect().top<700){
            animationNota.classList.add('animation_nota')
            //body.classList.add('body-scroll')
        }
    });
}


function navegacionFija(){
    const barra = document.querySelector('.barra');
    const nosotros = document.querySelector('.nosotros');
    const blog = document.querySelector('.blog');
    window.addEventListener('scroll', function(){
        if (nosotros.getBoundingClientRect().top<50){
            barra.classList.add('fijo')
            //body.classList.add('body-scroll')
        } else {
            barra.classList.remove('fijo')
            //body.classList.remove('body-scroll')
    }
    });
    window.addEventListener('scroll', function(){
        if (blog.getBoundingClientRect().top<100){
            barra.classList.add('fijo')
            //body.classList.add('body-scroll')
        } else {
            barra.classList.remove('fijo')
            //body.classList.remove('body-scroll')
    }
    });
}

function scrollNav(){
    const enlaces = document.querySelectorAll(".scroll");
    enlaces.forEach(enlace => {
        enlace.addEventListener('click', function(e){
            e.preventDefault();
            const seccionScroll= e.target.attributes.href.value;
            const seccion=document.querySelector(seccionScroll);
            seccion.scrollIntoView({behavior:"smooth"})
        });
    })
}
